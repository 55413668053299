<template>
  <div class="py-[0.78rem]">
    <template>
      <div class="flex px-6 w-full justify-between items-center">
        <div class="w-full flex justify-center items-center">
          <div v-if="getRSS.total_items > getRSS.limit">
            <TablePagination
              :clickedMethod="paginateRSS"
              :resultCount="getRSS.total_items"
              :itemsPerPage="getRSS.limit"
              :currentPage="getRSS.page"
              :totalPagesNumber="getRSS.total_pages"
            ></TablePagination>
          </div>
        </div>
        <PerPageFilter
          :limit="getRSS.limit"
          @click="getRSS.limit = $event; paginateRSS()"
        ></PerPageFilter>
      </div>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    TablePagination: () => import('@/ui/ui-kit/v2/TablePagination.vue'),
    PerPageFilter: () => import('@/ui/ui-kit/v2/PerPageFilter.vue'),
  },
  computed: {
    ...mapGetters([
      'getRSS'
    ]),
    ...mapActions([
      'fetchRSSList'
    ])
  },
  methods: {
    async paginateRSS (page) {
      await this.$store.dispatch('fetchRSSList', page)
    }
  }
}
</script>
